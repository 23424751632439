<template>
  <div class="brand-basic-table">
    <table>
      <thead>
        <tr>
          <th v-for="(column, i) in columns" :key="i">
            <div style="display: flex; justify-content: center;">
              <img
                v-if="column.icon"
                :src="getIconPath(column.icon)"
                alt="check"
                width="22px"
                height="22px"
              />
              <span>
                {{ column.text }}
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in details" :key="index">
          <td>
            {{ item.activity }}
            <div v-if="item.info && activeTabIndex !== 0" class="table-tooltip">
              <div class="table-info">
                {{ item.info }}
              </div>
              <span class="tooltiptext">{{ item.tooltip }}</span>
            </div>
          </td>
          <td>{{ item.points }}</td>
          <td>{{ item.amount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedMonthLabel: {
      type: String,
      required: true,
    },
    activeTabIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const getNameOfMonth = date => {
      const months = [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ];

      const d = date ? date : new Date();
      return months[d.getMonth()];
    };

    const monthDetails = ref([
      { activity: 'Ödül Yağmuru', points: 0, amount: '0', info: null, tooltip: null },
      { activity: 'Yap Kazan', points: 0, amount: '0', info: null, tooltip: null },
      { activity: 'Aylık Puanlama', points: 0, amount: '0', info: null, tooltip: null },
      { activity: 'Diğer Uygulamalar', points: 0, amount: '0', info: null, tooltip: null },
      { activity: 'Toplam', points: 0, amount: '0', info: null, tooltip: null },
    ]);

    const details = ref([]);

    const updateCategories = () => {
      let totalAmount = 0;
      let totalPoints = 0;

      details.value = monthDetails.value.map(m => {
        const matchingItem = props.items?.find(item => item.activity === m.activity);

        if (matchingItem) {
          const amount = matchingItem.amount
            ? parseFloat(matchingItem.amount.replace(/\+/g, '').replace(' TL', ''))
            : 0;
          const points = matchingItem.points
            ? parseFloat(matchingItem.points.replace(/\+/g, '').replace(' puan', ''))
            : 0;

          totalAmount += amount;
          totalPoints += points;

          const formattedAmount = amount % 1 === 0 ? amount.toFixed(0) : amount.toFixed(2);
          const formattedPoints = points % 1 === 0 ? points.toFixed(0) : points.toFixed(2);

          const amountPrefix = amount >= 0 ? '+ ' : '';
          const pointsPrefix = points >= 0 ? '+ ' : '';

          return {
            activity: m.activity,
            points: `${pointsPrefix}${formattedPoints} puan`,
            amount: `${amountPrefix}${formattedAmount} TL`,
            info: matchingItem.info || '',
            tooltip: matchingItem.tooltip || '',
          };
        } else {
          return {
            activity: m.activity,
            points: m.points,
            amount: m.amount,
            info: '',
            tooltip: '',
          };
        }
      });

      const totalAmountPrefix = totalAmount >= 0 ? '+ ' : '';
      const totalPointsPrefix = totalPoints >= 0 ? '+ ' : '';

      const totalIndex = details.value.findIndex(item => item.activity === 'Toplam');
      if (totalIndex !== -1) {
        details.value[totalIndex].amount =
          totalAmount !== 0 ? `${totalAmountPrefix}${totalAmount.toFixed(2)} TL` : '0';
        details.value[totalIndex].points =
          totalPoints !== 0 ? `${totalPointsPrefix}${totalPoints} puan` : '0';
      }
    };

    watch(
      () => props.items,
      () => {
        updateCategories();
      },
      { deep: true },
    );

    const getIconPath = iconName => {
      return require(`@/assets/icons/${iconName}.svg`);
    };

    return {
      details,
      getIconPath,
      getNameOfMonth,
    };
  },
};
</script>

<style scoped>
.brand-basic-table table {
  width: 100%;
  background: #f8f9f9;
  border-radius: 8px;
  border-style: hidden;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px #ededed;
}

.brand-basic-table td {
  color: #79838e;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.brand-basic-table tr:last-child td {
  color: #23303d;
}

.brand-basic-table td:first-child {
  color: #23303d;
  text-align: left;
  padding-left: 10px;
}

.brand-basic-table th,
.brand-basic-table td {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ededed;
}

.brand-basic-table th {
  font-size: 12px;
  font-weight: 500;
  color: #23303d;
  text-align: center;
}

.brand-basic-table th i {
  margin-right: 5px;
}
.table-info {
  background: linear-gradient(174deg, #d20051 -59.23%, #ffa300 96.23%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 10px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 20px;
  color: white;
  font-size: 10px;
  font-weight: 400;
  margin-left: 15px;
  position: relative;
}
.table-tooltip {
  position: relative;
  display: inline-block;
}

.table-tooltip .tooltiptext {
  color: #23303d;
  font-size: 14px;
  visibility: hidden;
  width: auto;
  min-width: 150px;
  background-color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.table-tooltip .tooltiptext::after {
  content: '';
  top: 50%;
  right: 100%;
  position: absolute;
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
}
.table-tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
